import { createContext, useCallback, useContext, useMemo } from 'react'

import { isAndroidApp, isIOSApp, isInHybridApp } from '@lib/hybrid-checker'

interface ProviderValue {
  isHybidLibEnabled: (type: HybridToggleType) => boolean
  getCameraSetting: (type: HybridToggleType) => HybridCameraSetting
}

const HybridContext = createContext<ProviderValue | undefined>(undefined)

interface HybridLibProviderProps extends HybridLibConfig {
  children: React.ReactNode
}
function HybridProvider({
  children,
  dlBackCameraSetting,
  dlBackPlatform,
  dlFrontCameraSetting,
  dlFrontPlatform,
  enableBackHybridLib,
  enableFrontHybridLib,
  enableHeadshotHybridLib,
  enableImageHybridLib,
  enableScannerHybridLib,
  headshotCameraSetting,
  headshotPlatform,
  imagePlatform,
  scannerPlatform,
}: HybridLibProviderProps): React.JSX.Element {
  const isHybidLibEnabled = useCallback(
    (type: HybridToggleType): boolean => {
      const isInApp = isInHybridApp()
      const isIOS = isIOSApp()
      const isAndroid = isAndroidApp()

      let platform: HybridPlatformToggleValue = 'Off'
      let enabled = false
      switch (type) {
        case 'headshot':
          enabled = enableHeadshotHybridLib
          platform = headshotPlatform
          break
        case 'front':
          enabled = enableFrontHybridLib
          platform = dlFrontPlatform
          break
        case 'back':
          enabled = enableBackHybridLib
          platform = dlBackPlatform
          break
        case 'image':
          enabled = enableImageHybridLib
          platform = imagePlatform
          break
        case 'scanner':
          enabled = enableScannerHybridLib
          platform = scannerPlatform
      }

      return (
        isInApp &&
        enabled &&
        (platform === 'Both' ||
          (isIOS && platform === 'iOS') ||
          (isAndroid && platform === 'Android'))
      )
    },
    [
      dlBackPlatform,
      dlFrontPlatform,
      enableBackHybridLib,
      enableFrontHybridLib,
      enableHeadshotHybridLib,
      enableImageHybridLib,
      enableScannerHybridLib,
      headshotPlatform,
      imagePlatform,
      scannerPlatform,
    ],
  )
  const getCameraSetting = useCallback(
    (type: HybridToggleType): HybridCameraSetting => {
      let setting: HybridCameraSetting

      switch (type) {
        case 'headshot':
          setting = headshotCameraSetting
          break
        case 'front':
          setting = dlFrontCameraSetting
          break
        case 'back':
          setting = dlBackCameraSetting
          break
      }

      if (setting === 'default' || setting === 'none') {
        return undefined
      }

      return setting
    },
    [dlBackCameraSetting, dlFrontCameraSetting, headshotCameraSetting],
  )
  const value = useMemo(() => {
    return {
      isHybidLibEnabled,
      getCameraSetting,
    }
  }, [getCameraSetting, isHybidLibEnabled])

  return <HybridContext.Provider value={value}>{children}</HybridContext.Provider>
}

function useHybridToggles(): ProviderValue {
  const context = useContext(HybridContext)

  if (context === undefined) {
    throw new Error('useHybridToggles must be used within a HybridProvider')
  }

  return context
}

export { HybridProvider, useHybridToggles }
